@import 'consts.scss';

body {
  margin: 0;
  font-family: 'Open Sans';
  font-size: 14px;
  line-height: 1.6rem;
  color: $color-black;
  background: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

.MuiFormControl-root {
  width: 100%;
  .MuiOutlinedInput-input {
    font-size: 13px;
    line-height: 16px;
    padding: 14.5px 14px;
    font-family: 'Open Sans';
    color: $color-black;
  }
  .MuiFormLabel-root {
    font-size: 11px;
    line-height: 0.7;
  }
  .MuiFormLabel-root.Mui-focused {
    color: $color-orange;
  }
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    background: white;
    padding-bottom: 10px;
    padding-right: 4px;
  }
  .MuiInput-underline:after {
    border-bottom-color: $color-orange;
  }
  .MuiOutlinedInput-root {
    fieldset {
      border-radius: 3px;
      border: $box-border;
      background: transparent !important;
    }
    &:hover fieldset {
      border: $box-border-hover;
    }
    &.Mui-focused fieldset {
      border: $box-border-focus;
    }
  }
}

.MuiMenuItem-root {
  font-family: 'Open Sans' !important;
  font-size: 11px !important;
  color: $color-black;
}

.cTable {
  .MuiTableCell-root {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100px;
    white-space: nowrap;
  }
}

.bold {
  font-weight: 600;
}

.green {
  color: $color-green;
}

.red {
  color: $color-red;
}

.orange {
  color: $color-orange;
}

.gray {
  color: $color-gray-medium;
}

.display-none {
  display: none !important;
}
