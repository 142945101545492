@import '../../styles/consts.scss';

.Toastify__close-button {
  color: black !important;
  position: absolute;
  top: 12px;
  right: 12px;
}
.Toastify__toast {
  width: 460px;
  right: 32px;

  display: flex;
  align-items: center;
  padding: 32px;
  box-shadow: 4px 2px 10px 0 rgba(0, 0, 0, 0.1), 4px 4px 7px 0 rgba(0, 0, 0, 0.11);
  background-color: $color-white;
  font-family: 'Open Sans';
  font-size: 1.6rem;
  line-height: 1.5;
  color: $color-black;
  border-radius: 2px;

  &:before {
    content: '';
    width: 38px;
    height: 38px;
    margin-right: 24px;
    z-index: 1;
  }

  &-container {
    width: 460px;
  }

  &-body {
    width: 310px;
  }

  &__title {
    font-family: 'Poppins';
    font-weight: 600;
  }
}

.Toastify__toast--default {
  border-left: 5px solid $color-white;
}
.Toastify__toast--info {
  // border-left: 5px solid $color-info;

  &:before {
    background: url(./icons/toast-info.svg) center/auto 100% no-repeat;
  }
}
.Toastify__toast--success {
  &:before {
    background: url(./icons/toast-success.svg) center/auto 100% no-repeat;
  }
}
.Toastify__toast--error {
  &:before {
    background: url(./icons/toast-error.svg) center/auto 100% no-repeat;
  }
}
