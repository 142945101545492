@import '../../styles/consts.scss';

:root {
  --font-text: 'Open Sans';
  --font-title: 'Poppins';
  --main-color: '#ec754a';
  --vh: 1vh;
  --vh20: calc(var(--vh, 1vh) * 20);
  --vh100: calc(var(--vh, 1vh) * 100);
}

.general-wrapper {
  max-width: 1160px;
  margin-right: 140px;
  margin-left: 140px;
  width: 100%;
  @media (max-width: 1170px) {
    margin-left: 48px;
    margin-right: 48px;
  }
  @media (max-width: 950px) {
    margin-left: 16px;
    margin-right: 16px;
  }
}

@media (max-width: 950px) {
  .only-desktop {
    display: none;
  }
}

.Landing {
  font-family: var(--font-text);
  background: white;
  &__header {
    background: #212121;
    color: white;
    font-family: 'Poppins';
    font-weight: 600;
    font-size: 20px;
    height: 96px;
    position: sticky;
    top: 0;
    &__menu {
      & > div {
        margin-left: 64px;
      }
    }
  }
  &__section1 {
    background: #212121;
    &__image {
      width: 50%;
      @media (max-width: 1024px) {
        width: 60%;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    &__text {
      width: 38%;
      margin-right: 56px;
      color: #ebebeb;
      font-size: 18px;
      line-height: 1.44;
      @media (max-width: 1024px) {
        margin-right: 0;
      }
    }
    &__title {
      font-family: 'Poppins';
      font-size: 38px;
      font-weight: 600;
      line-height: 1.37;
      margin-bottom: 40px;
      .orange {
        color: #ec754a;
      }
    }
    @media (max-width: 950px) {
      padding-bottom: 64px;
      .general-wrapper {
        flex-direction: column-reverse;
      }
      &__text {
        width: 100%;
        text-align: left;
      }
      &__title {
        font-size: 26px;
      }
      &__image {
        margin-bottom: 32px;
        width: 100%;
      }
    }
  }
  &__section2 {
    background: #f7f7f7;
    padding: 140px 0;
    &__anchor {
      font-family: 'Poppins';
      font-weight: 600;
      color: #666;
      font-size: 20px;
      margin-bottom: 24px;
    }
    &__image {
      width: 588px;
      font-family: 'Poppins';
      font-weight: 600;
      font-size: 24px;
      color: #ec754a;
      text-align: center;
      img {
        width: 100%;
        height: auto;
        margin-bottom: 48px;
      }
    }
    &__text {
      width: 440px;
      margin-left: 56px;
      color: #141414;
      font-size: 18px;
      line-height: 1.44;
    }
    &__title {
      font-family: 'Poppins';
      font-size: 38px;
      font-weight: 600;
      line-height: 1.37;
      margin-bottom: 40px;
      .orange {
        color: #ec754a;
      }
    }
    &__list {
      margin-top: 32px;
      font-size: 18px;
      font-weight: 600;
      line-height: 1.44;
      & > div > div {
        margin-bottom: 12px;
      }
      &__dot {
        background: #ec754a;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        margin-right: 12px;
      }
    }
    @media (max-width: 1380px) {
      .general-wrapper {
        flex-direction: column;
        align-items: center;
      }
      &__image {
        margin-bottom: 90px;
      }
      &__text {
        width: 100%;
        margin-left: 0;
      }
      br {
        display: none;
      }
      &__list {
        display: flex;
        justify-content: space-between;
      }
    }
    @media (max-width: 950px) {
      padding: 64px 0;
      &__image {
        width: 100%;
      }
      &__title {
        font-size: 26px;
      }
      &__list {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        & > div > div {
          flex-wrap: nowrap;
          align-items: baseline;
        }
      }
    }
  }
  &__section3 {
    padding: 96px 0 0 0;
    &__anchor {
      font-family: 'Poppins';
      font-weight: 600;
      color: #666;
      font-size: 20px;
      margin-bottom: 24px;
    }
    &__title {
      font-family: 'Poppins';
      font-size: 38px;
      font-weight: 600;
      line-height: 1.37;
      margin-bottom: 72px;
      color: #141414;

      .orange {
        color: #ec754a;
      }
    }
    &__item {
      width: 222px;
      margin-right: 40px;
      margin-bottom: 90px;
      font-size: 18px;
      line-height: 1.44;
      color: #141414;
      .bold {
        font-weight: bold;
      }
      &:nth-child(4n + 4) {
        margin-right: 0;
      }
      &__icon {
        margin-bottom: 24px;
      }
    }
    @media (max-width: 950px) {
      padding: 64px 0;
      .general-wrapper {
        text-align: center;
      }
      &__title {
        font-size: 26px;
      }
      &__list {
        justify-content: center;
        width: 100%;
        flex-direction: column;
        align-items: center;
      }
      &__item {
        margin-right: 0;
        margin-bottom: 40px;
      }
    }
  }
  &__section4 {
    background: #f7f7f7;
    padding: 140px 0;
    &__anchor {
      font-family: 'Poppins';
      font-weight: 600;
      color: #666;
      font-size: 20px;
      margin-bottom: 24px;
    }
    &__image {
      width: 539px;
      background: #ec754a;
      text-align: center;
      color: white;
      &__text {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 600;
        padding: 18px 24px;
        line-height: 1.6;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    &__text {
      width: 540px;
      color: #141414;
      font-size: 18px;
      line-height: 1.44;
    }
    &__title {
      font-family: 'Poppins';
      font-size: 38px;
      font-weight: 600;
      line-height: 1.37;
      margin-bottom: 40px;
    }
    @media (max-width: 1380px) {
      .general-wrapper {
        flex-direction: column;
        align-items: center;
      }
      &__text {
        width: 100%;
      }
      &__image {
        width: 539px;
        text-align: center;
        margin-top: 116px;
      }
    }
    @media (max-width: 950px) {
      padding: 64px 0;
      &__text {
        width: 100%;
      }
      &__image {
        width: 100%;
        margin-top: 48px;
        &__text {
          font-size: 14px;
        }
      }
      &__title {
        font-size: 26px;
      }
    }
  }
  &__footer {
    background: #212121;
    color: white;
    padding: 140px 0;
    font-size: 18px;

    &__col1 {
      width: 66%;
    }
    &__col2 {
      width: 20%;
      & > div {
        margin-bottom: 22px;
      }
    }
    &__text {
      font-size: 18px;
      color: #ebebeb;
      line-height: 1.44;
    }
    &__title {
      font-family: 'Poppins';
      font-size: 38px;
      font-weight: 600;
      line-height: 1.37;
      margin-bottom: 32px;
    }
    .bold {
      font-family: 'Poppins';
    }
    &__legal {
      margin-top: 140px;
      border-top: 2px solid #ec754a;
      padding-top: 24px;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.5;
      color: #ec754a;
    }
    @media (max-width: 1024px) {
      &__up {
        flex-direction: column;
      }
      &__col1,
      &__col2 {
        width: 100%;
      }
      &__col1 {
        margin-bottom: 116px;
      }
    }
    @media (max-width: 950px) {
      padding: 64px 0;
      &__title {
        font-size: 26px;
      }
      &__col1 {
        margin-bottom: 64px;
      }
      &__legal {
        margin-top: 80px;
      }
    }
  }
}
