/* CONSTS */
.Button {
  height: 32px;
  padding: 0 16px;
  background: #ec754a;
  color: white;
  font-size: 11px;
  font-weight: 600;
  font-family: 'Poppins';
  border: none;
  border-radius: 3px; }
  .Button:hover {
    background: #f0916e; }
  .Button:active {
    background: #ca613c; }
  .Button:disabled {
    background: #ebebeb;
    color: #999;
    pointer-events: none; }
  .Button__prefixIcon {
    margin-right: 7px; }

.Button--secondary.Button {
  background: white;
  color: #666;
  border: 1px solid #e0e0e0; }
  .Button--secondary.Button:hover {
    border: 1px solid #c7c7c7; }
  .Button--secondary.Button:active {
    border: 1px solid #c7c7c7;
    background: #f2f2f2; }
  .Button--secondary.Button:disabled {
    background: #ebebeb;
    color: #999;
    pointer-events: none; }

.SearchInput {
  background: white;
  width: 283px; }
  .SearchInput .MuiFormControl-root .MuiOutlinedInput-input {
    font-size: 13px; }

/* CONSTS */
.Select .MuiAutocomplete-root .MuiAutocomplete-endAdornment {
  top: auto; }

.Select .MuiSelect-select:focus {
  background: transparent; }

/* CONSTS */
.SelectBox {
  height: 56px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  box-sizing: border-box; }
  .SelectBox:hover {
    border: 1px solid #999; }
  .SelectBox--focus {
    border: 2px solid #ec754a !important; }
  .SelectBox__label {
    color: #999;
    font-size: 1.1rem;
    border-bottom: 1px solid #ebebeb;
    margin: 0 12px;
    padding: 5px 0; }
  .SelectBox__select {
    font-size: 1.1rem; }
    .SelectBox__select .MuiInputBase-root {
      padding: 0 12px !important;
      margin-top: 2px !important; }
      .SelectBox__select .MuiInputBase-root:before, .SelectBox__select .MuiInputBase-root:after {
        content: '';
        display: none; }
      .SelectBox__select .MuiInputBase-root .MuiSelect-select:focus {
        background: transparent; }
    .SelectBox__select .MuiSvgIcon-root {
      margin-right: 10px;
      margin-top: 4px; }
    .SelectBox__select .MuiAutocomplete-endAdornment {
      right: 7px; }

/* CONSTS */
.SwitchBox {
  width: auto;
  height: 40px;
  padding: 12px;
  padding-left: 16px;
  border: 1px solid #ebebeb;
  border-radius: 3px; }
  .SwitchBox__label {
    margin-right: 12px;
    color: #666;
    font-size: 1.2rem;
    font-weight: 500; }

/* CONSTS */
.cTable {
  box-shadow: none !important; }
  .cTable .MuiTableCell-root {
    font-family: "Open Sans";
    font-weight: 600;
    font-size: 1.3rem;
    color: #4d4d4d;
    border-bottom: 1px solid #ebebeb; }
  .cTable .MuiTableCell-stickyHeader {
    background-color: white;
    color: #666;
    text-transform: uppercase;
    font-family: 'Poppins';
    font-size: 1.1rem;
    font-weight: 500; }
  .cTable .MuiTypography-body2 {
    font-family: "Open Sans";
    font-size: 1.3rem;
    color: #666; }

/* CONSTS */
.Chip {
  height: 24px;
  padding: 0 12px;
  color: #666;
  font-weight: 600;
  font-size: 1.1rem;
  background: white;
  border: 1px solid white;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.2s ease-in, border 0.2s ease-in, color 0.2s ease-in; }
