/* CONSTS */

$font: 'Open Sans';

// Breakpoints
$screen-breakpoints: (
  'ul': 1920,
  'xl': 1366,
  'lg': 1024,
  'md': 768,
  'sm': 576,
  'xs': 425,
  'us': 375
);

$breakpoint-tablet: 768px;
$breakpoint-desktop: 1199px;

$max-width: 1024px;

// Zindex
$zindex-dropdown: 1000 !default;
$zindex-sticky: 1020 !default;
$zindex-fixed: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-spinner: 1080 !default;

// Grid
$gutter: 4px;
$grid-gutter-width: $gutter * 4; // 16px
$grid-gutter-height: $gutter * 7; // 28px
$grid-column-mininum-width: $gutter * 36; // 144px

// Blocks
$sidebar-width: $gutter * 45; // 180px
$sidebar-width-collapsed: $gutter * 16; // 64px
$topbar-height: $gutter * 12; // 48px

// Weights
$open-sans-regular: 400;
$open-sans-semibold: 600;
$open-sans-bold: 700;

// Colors
$color-black: #4d4d4d;
$color-black2: #333;
$color-red: #fa3d3d;
$color-red2: #ec0000;
$color-red3: #ef4c52;
$color-blue: #3584da;
$color-blue1: #2598e4;
$color-blue2: #069ead;
$color-blue-light: #f6f9fb;
$color-blue-light2: #e5eff5;
$color-blue-light3: #f0f5f8;
$color-blue-dark: #134863;
$color-blue-dark2: #1b53a6;
$color-gray: #666;
$color-gray2: #b3b3b3;
$color-gray-medium: #999;
$color-gray-light: #ccc;
$color-gray-light2: #f7f7f7;
$color-gray-light3: #ebebeb;
$color-gray-light4: #c7c7c7;
$color-gray-light5: #e0e0e0;
$color-gray-light6: #f2f2f2;
$color-gray-dark: #141414;
$color-gray-dark1: #262626;
$color-gray-dark2: #333;
$color-green: #24a453;
$color-green2: #a1b24e;
$color-info: $color-blue;
$color-ok: $color-green;
$color-yellow: #ffd000;
$color-warning: #e28f01;
$color-white: #ffffff;
$color-white2: #fafafa;
$color-orange: #ec754a;
$color-orange-light: #f0916e;
$color-orange-dark: #ca613c;
$color-orange-dark2: #3e261e;
$color-orange-dark3: #593426;
$color-yellow: #dcac21;
$color-purple: #dd7ed0;

$input-disabled: $color-gray-light3;
$button-disabled: $color-gray-light3;
$button-disabled-font: $color-gray-medium;
$button-primary-font: $color-white;
$button-primary: $color-blue;
$button-primary-hover: #2369d2;
$button-primary-pressed: #1b53a6;
$button-secondary-font: $color-black;
$button-secondary: $color-gray-light3;
$button-secondary-pressed: #dbdbdb;
$button-white: $color-white;
$button-white-border: $color-gray-light;
$button-white-font: $color-black;
$button-white-pressed-font: $color-blue;

$box-border: 1px solid $color-gray-light3;
$box-border-dotted: 1px solid $color-gray-light3;
$box-border-hover: 1px solid $color-gray-medium;
$box-border-focus: 2px solid $color-orange;

$body-background: #f6f9fc;
$row-hover: #e2edf4;
$row-even: #f6f9fb;
$row-selected: #fff8cf;
